'use client'

/* eslint-disable react/no-unknown-property */
import { FC } from 'react'
import cn from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import { MapIcon } from '@components/icons'
import { Container, Email } from '@components/ui'
import { AllCmsPages } from '@models'
import { useTaxonsContext } from '@contexts/taxonsContext'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { MapIconThird } from '@components/iconsSetThree'
import { Icon } from '@components/common/Icon'
import { MapIconNinelife } from '@components/iconSetNinelife'
import { useBrandThemeContext } from '@contexts/brandThemeContext'
import { MapNinefit } from '@components/iconSetNinefit/MapNinefit'
import { MapIconCopperStudio } from '@components/iconSetCopperStudio/MapIconCopperStudio'
import { BANNER_TYPE } from '@constants/common'
import { usePathname } from 'next/navigation'
import s from './Footer.module.css'
import { Subscription } from './Subscription'
import { StayConnected } from './StayConnected/StayConnected'

interface Props {
  className?: string
  allPages?: AllCmsPages
  allPagesFetched: boolean
}

interface ILink {
  href: string
  title: string
}

const Phone = dynamic(() => import('@components/ui/Phone'), { ssr: false })

const Footer: FC<Props> = ({ className, allPages, allPagesFetched }) => {
  const pathname = usePathname()
  const rootClassName = cn(s.root, className)
  const allCmsPagesData = allPages?.data

  const taxons = useTaxonsContext()
  const {
    data: {
      attributes: {
        description,
        contact_phone: contactPhone,
        customer_support_email: customerSupportEmail,
        mailing_address: mailingAddress,
        show_tracking_page: showTrakingPage,
        payment_icons_list: paymentIcons,
        all_blogs_url: allBlogsUrl,
        is_home_brand: isHomeBrand,
      },
    },
  } = useStoreDataContext()
  const { show_footer_logo, watermark_url, home_page_small_banner_type } =
    useBrandThemeContext()
  const isNa = home_page_small_banner_type === BANNER_TYPE.NINTH_AVENUE
  const getCurrentYear = () => new Date().getFullYear()

  const policiesLinks: ILink[] = [
    {
      href: '/policy/shipping-and-delivery-policy',
      title: 'Shipping and Delivery Policy',
    },
    { href: '/policy/return-policy', title: 'Return and Refund Policy' },
    { href: '/policy/payments-policy', title: 'Payments Policy' },
    { href: '/policy/privacy-policy', title: 'Privacy Policy' },
    { href: '/policy/terms-of-service', title: 'Terms and Conditions' },
  ]

  const isPageExists = (href: string) => {
    if (!allPagesFetched) return true
    if (!allCmsPagesData) return false

    const presence = allCmsPagesData.filter(
      el => `/${el.attributes.slug}` === href,
    )

    return presence.length > 0
  }

  return (
    <footer className={`${rootClassName} bg-footerBackground`}>
      <Container>
        <div className={cn('my-0 mx-auto sm:px-0 px-4', s.wrapper)}>
          {show_footer_logo ? (
            <span className={s.watermarkWrapper}>
              {watermark_url ? (
                <img src={watermark_url} alt='watermark' />
              ) : null}
            </span>
          ) : null}

          <div className='flex flex-row-reverse flex-wrap items-start justify-between z-10 -mx-4'>
            <div className='px-4 2xl:w-6/12 lg:w-7/12 w-full'>
              <div className='flex flex-row flex-wrap sm:-mx-4 justify-between'>
                <div
                  className={`${s.footerNavColumn} mb-8 lg:mb-0 pb-8 sm:pb-0 lg:w-1/3 md:w-2/6 sm:w-1/2 w-full sm:px-4 sm:border-0 border-b border-base-border`}
                >
                  {/* eslint-disable-next-line react/no-unknown-property */}
                  <span data-testid='categories' className={s.footerTitle}>
                    Categories
                  </span>
                  {taxons.map(taxon => (
                    <Link
                      className={`${s.linkItem} ${
                        pathname?.includes(taxon.href) ? s.linkSelected : ''
                      }`}
                      title={taxon.name}
                      href={taxon.href || '#'}
                      key={taxon.id}
                    >
                      {taxon.name}
                    </Link>
                  ))}
                </div>

                <div
                  className={`${s.footerNavColumn} mb-8 lg:mb-0 pb-8 sm:pb-0 lg:w-1/3 md:w-2/6 sm:w-1/2 w-full sm:px-4 sm:border-0 border-b border-base-border`}
                >
                  <span data-testid='policies' className={s.footerTitle}>
                    Policies
                  </span>
                  {policiesLinks.map(el => {
                    if (isPageExists(el.href)) {
                      return (
                        <Link
                          data-testid='footer_policy_link'
                          className={`${s.linkItem} ${
                            pathname?.includes(el.href) ? s.linkSelected : ''
                          }`}
                          prefetch={false}
                          title={el.title}
                          href={el.href}
                          key={el.title}
                        >
                          {el.title}
                        </Link>
                      )
                    }

                    return null
                  })}
                </div>

                <div
                  className={`${s.footerNavColumn} mb-8 lg:mb-0 pb-8 sm:pb-0 xl:w-1/3 lg:w-auto md:w-2/6 sm:w-1/2 w-full sm:px-4 sm:border-0 border-b border-base-border`}
                >
                  {isPageExists('/help') ? (
                    <Link
                      data-testid='footer_support'
                      prefetch={false}
                      className={s.footerTitle}
                      title='Help'
                      href='/help'
                    >
                      Support
                    </Link>
                  ) : (
                    <span
                      data-testid='footer_support'
                      className={s.footerTitle}
                    >
                      Support
                    </span>
                  )}
                  {isPageExists('/about-us') && (
                    <Link
                      data-testid='footer_about_us'
                      prefetch={false}
                      className={`${s.linkItem} ${
                        pathname?.includes('/about-us') ? s.linkSelected : ''
                      }`}
                      title='About Us'
                      href='/about-us'
                    >
                      About Us
                    </Link>
                  )}
                  {isPageExists('/contact-us') && (
                    <Link
                      data-testid='footer_contact_us'
                      prefetch={false}
                      className={`${s.linkItem} ${
                        pathname?.includes('/contact-us') ? s.linkSelected : ''
                      }`}
                      title='Contact Us'
                      href='/contact-us'
                    >
                      Contact Us
                    </Link>
                  )}
                  <Link
                    data-testid='footer_bulk_order'
                    prefetch={false}
                    className={`${s.linkItem} ${
                      pathname?.includes('/bulk-order') ? s.linkSelected : ''
                    }`}
                    title='Bulk Order'
                    href='/bulk-order'
                  >
                    Bulk Order
                  </Link>
                  {showTrakingPage && (
                    <Link
                      data-testid='footer_track_your_order'
                      prefetch={false}
                      className={`${s.linkItem} ${
                        window.location.href.includes('/track')
                          ? s.linkSelected
                          : ''
                      }`}
                      title='Track Your Order'
                      href='/track'
                    >
                      Track Your Order
                    </Link>
                  )}

                  {isPageExists('/faq') && (
                    <Link
                      data-testid='footer_faq'
                      prefetch={false}
                      aria-label='frequently asked questions'
                      className={`${s.linkItem} ${
                        pathname?.includes('/faq') ? s.linkSelected : ''
                      }`}
                      title='FAQ'
                      href='/faq'
                    >
                      FAQ
                    </Link>
                  )}
                  {allBlogsUrl && (
                    <Link
                      data-testid='footer_faq'
                      prefetch={false}
                      aria-label='blogs'
                      className={`${s.linkItem} ${
                        pathname?.includes('/faq') ? s.linkSelected : ''
                      }`}
                      title='Blogs'
                      href={allBlogsUrl}
                    >
                      Blogs
                    </Link>
                  )}
                </div>

                {isHomeBrand && <StayConnected isSmall={false} />}
              </div>
            </div>
            <div className='flex sm:flex-col-reverse flex-col lg:w-5/12 w-full px-4'>
              <div className='flex flex-col items-start justify-start sm:mt-10 sm:mb-0 mb-8 pb-8 sm:pb-0 sm:border-0 border-b border-base-border'>
                <p className={s.footerText}>
                  Subscribe to our Newsletter to receive updates, access to
                  exclusive deals, and more.
                </p>
                <Subscription />
                {/* <span className={s.footerTitle}>Connect with us</span>
              <div className={s.footerSocialList + ' -mt-8'}>
                <a
                  href={data?.attributes?.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className={s.footerSocialItem}
                  title="Instagram"
                >
                  <SocInstagram />
                </a>
                <a
                  href={data?.attributes?.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className={s.footerSocialItem}
                  title="Facebook"
                >
                  <SocFacebook />
                </a>
              </div> */}
              </div>
              <div
                className={cn(s.footerNavColumn, s.footerNavColumnContacts, {
                  'sm:border-0 border-b border-base-border mb-8 lg:mb-0 pb-8 sm:pb-0':
                    isHomeBrand,
                })}
              >
                <span data-testid='footer_info' className={s.footerTitle}>
                  Info
                </span>
                {contactPhone && (
                  <Phone
                    value={contactPhone}
                    auditId='footer_info_phone'
                    linkStyles={s.linkContactItem}
                    iconStyles={`${s.linkItemIcon} text-footerIcon`}
                    description='24/7 Support'
                  />
                )}
                {customerSupportEmail && (
                  <Email
                    auditId='footer_info_email'
                    value={customerSupportEmail}
                    linkStyles={s.linkContactItem}
                    iconStyles={`${s.linkItemIcon} text-footerIcon`}
                  />
                )}
                {mailingAddress && (
                  <div
                    className={`${s.linkContactItem} ${s.linkContactAddressWrapper}`}
                  >
                    <Icon
                      set1={
                        <MapIcon className='text-footerIcon w-7 mr-1 mt-1 pr-2 shrink-0' />
                      }
                      set2={
                        <MapIcon className='text-footerIcon w-7 mr-1 mt-1 pr-2 shrink-0' />
                      }
                      set3={
                        <MapIconThird className='text-footerIcon w-7 mr-1 mt-1 pr-2 shrink-0' />
                      }
                      setNinelife={
                        <MapIconNinelife className='text-footerIcon w-7 mr-1 mt-1 pr-2 shrink-0' />
                      }
                      setNinefit={
                        <MapNinefit className='text-footerIcon w-7 mr-1 mt-1 pr-2 shrink-0' />
                      }
                      setCopperStudio={
                        <MapIconCopperStudio className='text-footerIcon w-7 mr-1 mt-1 pr-2 shrink-0' />
                      }
                    />
                    <pre className={s.linkContactAddress}>{mailingAddress}</pre>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isHomeBrand && <StayConnected isSmall />}
          <div className={s.footerBottom}>
            {paymentIcons && (
              <div className={s.footerBottomRight}>
                {isNa ? 'Available Payment Options:' : ''}
                {paymentIcons.map(icon => (
                  <span key={icon.id} className={s.payItem}>
                    <img alt={icon.name} src={icon.url} />
                  </span>
                ))}
              </div>
            )}

            <div className={s.footerBottomLeft}>
              <span className='mr-0 lg:mr-20 md:mt-0 mt-4'>
                &copy; {getCurrentYear()},{' '}
                <Link className='hover:underline' href='/'>
                  {description}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
